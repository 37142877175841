// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-templates-post-js": () => import("./../../../src/templates/post.js" /* webpackChunkName: "component---src-templates-post-js" */),
  "component---src-templates-template-about-js": () => import("./../../../src/templates/template__about.js" /* webpackChunkName: "component---src-templates-template-about-js" */),
  "component---src-templates-template-contact-js": () => import("./../../../src/templates/template__contact.js" /* webpackChunkName: "component---src-templates-template-contact-js" */),
  "component---src-templates-template-portfolio-js": () => import("./../../../src/templates/template__portfolio.js" /* webpackChunkName: "component---src-templates-template-portfolio-js" */),
  "component---src-templates-template-project-js": () => import("./../../../src/templates/template__project.js" /* webpackChunkName: "component---src-templates-template-project-js" */),
  "component---src-templates-template-services-js": () => import("./../../../src/templates/template__services.js" /* webpackChunkName: "component---src-templates-template-services-js" */),
  "component---src-templates-template-subpage-commercial-js": () => import("./../../../src/templates/template__subpage__commercial.js" /* webpackChunkName: "component---src-templates-template-subpage-commercial-js" */),
  "component---src-templates-template-subpage-residential-js": () => import("./../../../src/templates/template__subpage__residential.js" /* webpackChunkName: "component---src-templates-template-subpage-residential-js" */),
  "component---src-templates-template-subpage-styling-js": () => import("./../../../src/templates/template__subpage__styling.js" /* webpackChunkName: "component---src-templates-template-subpage-styling-js" */)
}

